<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>供应商名称：</span>
          <i-input placeholder="请输入" :value="detailsInfo.supplier_name" class="iviewIptWidth250 marginRight60" disabled></i-input>
        </FormItem>
        <FormItem>
          <span>收货仓库：</span>
          <i-input placeholder="请输入" :value="detailsInfo.warehouse_name" class="iviewIptWidth250 marginRight60" disabled></i-input>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :data="meterList" border>
        <template slot-scope="{ row, index }" slot="num">
<!--          <i-input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeIpt($event, 'quantity', index)"></i-input>-->
          <InputNumber :max="999999" :min="0" :precision="0" :value="row.quantity" :active-change="false"  style="width: 100px;" @on-change="changeTotal($event, 'quantity', index)"></InputNumber>
        </template>
      </Table>
      <div class="summary" v-if="totleMoney > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ allNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totleMoney }}</span
          >
        </div>
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength='100' show-word-limit placeholder="请输入" v-model="detailsInfo.note"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnReset marginLeft20 fr" @click="save(1)">提交</span>
      <span class="pageBtn finger btnSure fr" @click="save(0)">保存</span>
    </div>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'editPurchaseReturnConsign',
  components: {
    TipsComponent,
  },
  computed: {
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.number * 1
      })
      return totle
    },
    totleMoney() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.number * e.unit_price
      })
      return totle.toFixed(2)
    },
  },
  data() {
    return {
      deleteStatus: false,
      postStatus: false,
      storeList: [],
      supplierList: [],
      meterList: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '单价',
          align: 'center',
          width: 200,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '数量',
          slot: 'num',
          align: 'center',
          width: 200,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 200,
        },
        {
          title: '小计',
          align: 'center',
          width: 200,
          render: (h, param) => {
            return h('div', [h('span', param.row.subtotal ? '¥' + param.row.subtotal : '')])
          },
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      id: '', // 编辑的采购单id
      postFrom: {
        supplier_id: '',
        line_info: [],
        note: '',
        warehouse_id: '',
      },
      detailsInfo: {},
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.queryDetails(this.id)
  },
  methods: {
    // 保存
    save(num) {
      for (let i = 0; i < this.meterList.length; i++) {
        // this.meterList[i].product_id = this.meterList[i].id
        this.meterList[i].product_id = this.meterList[i].product_id
        this.meterList[i].unit_price = this.meterList[i].unit_price * 1
        if (!this.meterList[i].quantity) {
          this.$Message.warning(`请完善第${i + 1}组产品的数量`)
          return
        }
      }
      this.postFrom.supplier_id = this.detailsInfo.supplier_id
      this.postFrom.warehouse_id = this.detailsInfo.warehouse_id
      this.postFrom.line_info = JSON.parse(JSON.stringify(this.meterList))
      this.postFrom.id = this.id
      this.postFrom.note = this.detailsInfo.note
      if (num) {
        this.postFrom.is_submit = '1'
        this.postStatus = true
        return
      }
      this.$http.put(this.$apiConsign.pruchaseReturnManage, this.postFrom, true).then(res => {
        this.$router.go(-1)
      })
      sessionStorage.setItem('updataCache','0')
    },
    // 最终确认提交
    surePost() {
      this.postStatus = false
      this.$http.put(this.$apiConsign.pruchaseReturnManage, this.postFrom, true).then(res => {
        this.$router.go(-1)
      })
      sessionStorage.setItem('updataCache','0')
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      console.log(this.meterList, '删除')
    },
    // 点击删除按钮
    deleteRow(index) {
      if (this.meterList.length == 1) {
        this.$Message.warning('至少保留一个产品')
        return
      }
      this.clickIndex = index
      this.deleteStatus = true
    },
    changeTotal(e, name, index) {
      this.$set(this.meterList[index], name, e * 1)
      let subtotal = this.meterList[index].quantity * this.meterList[index].unit_price
      this.$set(this.meterList[index], 'subtotal', subtotal > 0 ? subtotal.toFixed(2) : 0)
    },
    // 改变数据
    changeIpt(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
      } else {
        this.$set(this.meterList[index], name, e.target.value.trim() * 1)
      }
      let subtotal = this.meterList[index].quantity * this.meterList[index].unit_price
      this.$set(this.meterList[index], 'subtotal', subtotal > 0 ? subtotal.toFixed(2) : 0)
    },
    // 查询详情
    queryDetails(id) {
      this.$http.get(this.$apiConsign.pruchaseReturnDetail, { id: id }, true).then(res => {
        this.detailsInfo = res.data
        this.meterList = res.data.purchase_return_line
      })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;
      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
